import "../scss/app.scss";

import Swiper from "swiper";
import 'swiper/scss';
import 'swiper/scss/navigation';
// import 'swiper/css/effect-creative';
import { Navigation, EffectCreative, FreeMode, Autoplay } from "swiper/modules";

import Inputmask from "inputmask";
import Tabs from "./tabs";
import { MobileMenu } from "./mobile-menu";
import Modal from "./modal";
import Form from "./form";


const phoneInput = document.querySelector("[data-inputmask]");
const phoneModalInput = document.querySelector("[data-form-inputmask]");

const im = new Inputmask("+38(099)-999-9999");

im.mask(phoneInput);
im.mask(phoneModalInput);

document.querySelectorAll("[data-swiper-gallery]").forEach((s, i) => {
  const swiper = new Swiper(s, { speed: 700, loop: true, spaceBetween: 25 });

  addDelayToNavigationButtons(swiper, i);
});

function addDelayToNavigationButtons(swiper, i) {
  const nextButton = document.querySelector("[data-swiper-gallery-btn-next]");
  const prevButton = document.querySelector("[data-swiper-gallery-btn-prev]");

  nextButton.addEventListener('click', function(event) {
    event.preventDefault();
    setTimeout(() => {
      swiper.slideNext();
    }, i * 250 - (i * 200));
  });

  prevButton.addEventListener('click', function(event) {
    console.log(prevButton)
    event.preventDefault();
    setTimeout(() => {
      swiper.slidePrev();
    }, 0);
  });
}

new Swiper("[data-swiper-feedbaks]", {
  modules: [Navigation, EffectCreative],
  speed: 700,
  navigation: {
    prevEl: "[data-swiper-feedbacks-btn-prev]",
    nextEl: "[data-swiper-feedbacks-btn-next]"
  },
  grabCursor: true,
  effect: "creative",
  creativeEffect: {
    prev: {
      // shadow: true,
      translate: [0, "-120%", -400],
    },
    next: {
      translate: ["100%", 0, 0],
    },
  }
});

MobileMenu();

let slidesCount = 1;

const prevSlideBtn = document.querySelector("[data-products-prev-btn]");
const nextSlideBtn = document.querySelector("[data-products-next-btn]");
const currSlideNumber = document.querySelector("[data-products-curr-slide]");

const slides = document.querySelector("[data-products-slider]");

prevSlideBtn.addEventListener("click", () => {
  if (slidesCount < 2) return;
  
  slides.style.transform = `translateX(${-100 * slidesCount + 200}%)`;
  currSlideNumber.innerHTML = slidesCount - 1;
  
  slidesCount--;
})

nextSlideBtn.addEventListener("click", () => {
  if (slidesCount > 2) return;

  
  slides.style.transform = `translateX(${-100 * slidesCount}%)`;
  currSlideNumber.innerHTML = slidesCount + 1;

  slidesCount++;
});

new Swiper("[data-sertificates-swiper]", {
  modules: [Navigation],
  slidesPerView: "auto",
  spaceBetween: 20,
  speed: 700,
  navigation: {
    prevEl: "[data-swiper-sertificates-btn-prev]",
    nextEl: "[data-swiper-sertificates-btn-next]"
  },
});

new Tabs("[data-technologies-tabs]");

new Swiper("[data-partners-swiper]", {
  modules: [FreeMode, Autoplay],

  slidesPerView: "auto",
  spaceBetween: 68,
  freeMode: true,
  autoplay: {
    delay: 0,
  },
  speed: 3000,
  loop: true,
  loopAddBlankSlides:true,
  centeredSlides: true,
  
  breakpoints: {
    900: {
      spaceBetween: 0,
      slidesPerView: 3,
      autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
      },
      loop: false,
      centeredSlides: false,
      freeMode: false,
      speed: 700,
    }
  }
});

new Form("[data-form]");

new Form("[data-modal]");
new Modal("[data-modal]", "[data-open-product-modal]");

new Modal("[data-success-modal]");

// const section = document.querySelector('section');

// const observer = new IntersectionObserver((entries) => {
//   entries.forEach((entry) => {
//     if (entry.isIntersecting) {
//       // Call a function to handle scrolling behavior
//       handleSectionScroll();
//     }
//   });
// });

// observer.observe(section);

// const handleSectionScroll = () => {
//   document.querySelector("body").addEventListener('wheel', scrollBody);

//   const scrollBody = (event) => {
//     const sectionHeight = section.getBoundingClientRect().height;
//     const bodyHeight = document.body.getBoundingClientRect().height;
//     const scrollPosition = window.pageYOffset;

//     if (scrollPosition < section.offsetTop) {
//       // Scroll normally
//       return;
//     }

//     if (scrollPosition >= section.offsetTop && scrollPosition < 
//        section.offsetTop + sectionHeight - bodyHeight) {
//        // Scroll the section
//        section.scrollBy(0, event.deltaY);
//        event.preventDefault();
//     } else {
//       // Scroll normally
//       return;
//     }
//   }
// }